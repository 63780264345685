.page-enter {
  opacity: 0.01;
  transition: opacity 0.2s ease-in;
}

.page-enter.page-enter-active {
  opacity: 1;
}

.page-leave {
  opacity: 0;
}

.page-leave.page-leave-active {
  opacity: 0;
}

body {
  margin: 0;
}
