.fixedDataTableLayout_main {
  font-family: Raleway;
  color: #4e4e4e;
  font-weight: 500;
  font-size: 14px;
  font-weight: 500;
  margin-top: 50px;
  border-width: 0;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.fixedDataTableCellLayout_main {
  display: block;
  border-width: 0;
  overflow: hidden;
}

.fixedDataTableLayout_hasBottomBorder {
  border-bottom-style: none;
  border-bottom-width: 0;
}

.fixedDataTableLayout_header {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  border-width: 0;
}

.public_fixedDataTable_header .public_fixedDataTableCell_main {
  border-top-width: 0;
  background-color: rgb(226, 227, 228);
  background-image: none;
}

.data-row {
  background-color: #f6f7f8;
  border-bottom: #fff 6px solid;
}

.public_Scrollbar_main.public_Scrollbar_mainActive,
.public_Scrollbar_main:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.public_Scrollbar_mainOpaque,
.public_Scrollbar_mainOpaque.public_Scrollbar_mainActive,
.public_Scrollbar_mainOpaque:hover {
  background-color: #fff;
}

.public_Scrollbar_face:after {
  background-color: #c2c2c2;
}

.public_Scrollbar_main:hover .public_Scrollbar_face:after,
.public_Scrollbar_mainActive .public_Scrollbar_face:after,
.public_Scrollbar_faceActive:after {
  background-color: #7d7d7d;
}
