@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway-Regular.ttf');
}

@font-face {
  font-family: 'HelveticaNeueLTStd-LtEx';
  src: url('../../assets/fonts/HelveticaNeueLTStd-LtEx.ttf');
}

@font-face {
  font-family: 'BodoniPoster';
  src: url('../../assets/fonts/Bodoni-Poster.ttf');
}

@font-face {
  font-family: 'BodoniBold';
  src: url('../../assets/fonts/BodoniFLF-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../../assets/fonts/decleor/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'PlayfairDisplay-Regular';
  src: url('../../assets/fonts/decleor/PlayfairDisplay-Regular.ttf');
}

@font-face {
  font-family: 'PlayfairDisplay-Italic';
  src: url('../../assets/fonts/decleor/PlayfairDisplay-Italic.ttf');
}

/* Essie */
@font-face {
  font-family: 'Univers-Bold';
  src: url('../../assets/fonts/essie/Univers-Bold.otf');
}

@font-face {
  font-family: 'Univers-Light';
  src: url('../../assets/fonts/essie/Univers-Light.otf');
}

/* Care Cosmetics */
@font-face {
  font-family: 'AGaramondPro-Regular';
  src: url('../../assets/fonts/carecosmetics/AGaramondPro-Regular.otf');
}

@font-face {
  font-family: 'HelveticaNeueLTStd-Lt';
  src: url('../../assets/fonts/carecosmetics/HelveticaNeueLTStd-Lt.otf');
}

/* UFS */
@font-face {
  font-family: 'DINPro-Medium';
  src: url('../../assets/fonts/ufs/DINPro-Medium.ttf');
}

@font-face {
  font-family: 'DINPro-Black';
  src: url('../../assets/fonts/ufs/DINPro-Black.ttf');
}

/* RPB */
@font-face {
  font-family: 'BentonSans-Regular';
  src: url('../../assets/fonts/rpb/BentonSans-Regular.otf');
}

@font-face {
  font-family: 'BentonSans-Medium';
  src: url('../../assets/fonts/rpb/BentonSans-Medium.otf');
}

@font-face {
  font-family: 'BentonSans-Bold';
  src: url('../../assets/fonts/rpb/BentonSans-Bold.otf');
}

/* NF */

@font-face {
  font-family: 'DINNextLTPro-Bold';
  src: url('../../assets/fonts/nf/DINNextLTPro-Bold.otf');
}

@font-face {
  font-family: 'DINNextLTPro-Regular';
  src: url('../../assets/fonts/nf/DINNextLTPro-Regular.otf');
}

@font-face {
  font-family: 'DINNextLTPro-Medium';
  src: url('../../assets/fonts/nf/DINNextLTPro-Medium.otf');
}

div {
  font-family: 'Raleway';
  font-size: '14px';
}

/* LPPD */

@font-face {
  font-family: 'ProximaNova';
  src: url('../../assets/fonts/nf/DINNextLTPro-Regular.otf');
}

/* BD */
@font-face {
  font-family: 'Roboto-Medium';
  src: url('../../assets/fonts/bd/Roboto-Medium.ttf');
}
@font-face {
  font-family: 'Roboto-Black';
  src: url('../../assets/fonts/bd/Roboto-Black.ttf');
}
@font-face {
  font-family: 'Raiderfont';
  src: url('../../assets/fonts/bd/Raiderfont.ttf');
}
